export const norwegian = {
  "startsWith": "Starter med",
  "contains": "Inneholder",
  "notContains": "Inneholder ikke",
  "endsWith": "Slutter på",
  "equals": "Er lik",
  "notEquals": "Er ikke lik",
  "noFilter": "Ikke filtrert",
  "filter": "Filter",
  "lt": "Mindre enn",
  "lte": "Mindre enn eller lik",
  "gt": "Større enn",
  "gte": "Større enn eller lik",
  "dateIs": "Dato er",
  "dateIsNot": "Dato er ikke",
  "dateBefore": "Dato er før",
  "dateAfter": "Dato er etter",
  "custom": "Egendefinert",
  "clear": "Tøm",
  "apply": "Bruk",
  "matchAll": "Matcher alle",
  "matchAny": "Matcher noen",
  "addRule": "Legg til regel",
  "removeRule": "Fjern regel",
  "accept": "Ja",
  "reject": "Nei",
  "choose": "Velg",
  "upload": "Last opp",
  "cancel": "Avbryt",
  "completed": "Ferdig",
  "pending": "Venter",
  "dayNames": ["Søndag","Mandag","Tirsdag","Onsdag","Torsdag","Fredag","Lørdag"],
  "dayNamesShort": ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
  "dayNamesMin": ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
  "monthNames": ["Januar","Februar","Mars","April","Mai","Juni","Juli","August","September","Oktober","November","Desember"],
  "monthNamesShort": ["Jan","Feb","Mar","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Des"],
  "chooseYear": "Velg år",
  "chooseMonth": "Velg måned",
  "chooseDate": "Velg dato",
  "prevDecade": "Forrige årtiende",
  "nextDecade": "Nest årtiende",
  "prevYear": "Forrige år",
  "nextYear": "Neste år",
  "prevMonth": "Forrige måned",
  "nextMonth": "Neste måned",
  "prevHour": "Forrige time",
  "nextHour": "Neste time",
  "prevMinute": "Forrige minutt",
  "nextMinute": "Neste minutt",
  "prevSecond": "Forrige sekund",
  "nextSecond": "Neste sekund",
  "am": "am",
  "pm": "pm",
  "today": "I dag",
  "weekHeader": "Uke",
  "firstDayOfWeek": 1,
  "dateFormat": "dd.mm.yy",
  "weak": "Svakt",
  "medium": "Middels",
  "strong": "Sterkt",
  "passwordPrompt": "Skriv et passord",
  "emptyFilterMessage": "Ingen resultater funnet",
  "searchMessage": "{0} resultater er tilgjengelige",
  "selectionMessage": "{0} oppføringer valgt",
  "emptySelectionMessage": "Ingen valgte oppføringer",
  "emptySearchMessage": "Ingen resultater funnet",
  "emptyMessage": "Ingen tilgjengelige valg",
  "aria": {
    "trueLabel": "På",
    "falseLabel": "Av",
    "nullLabel": "Ikke valgt",
    "star": "1 stjerne",
    "stars": "{star} stjerner",
    "selectAll": "Alle oppføringer valgt",
    "unselectAll": "Ingen oppføringer valgt",
    "close": "Lukk",
    "previous": "Forrige",
    "next": "Neste",
    "navigation": "Navigasjon",
    "scrollTop": "Skroll til toppen",
    "moveTop": "Flytt til toppen",
    "moveUp": "Flytt opp",
    "moveDown": "Flytt ned",
    "moveBottom": "Flytt til bunnen",
    "moveToTarget": "Flytt til mål",
    "moveToSource": "Flytt til kilde",
    "moveAllToTarget": "Flytt alle til mål",
    "moveAllToSource": "Flytt alle til kilde",
    "pageLabel": "{page}",
    "firstPageLabel": "Første side",
    "lastPageLabel": "Siste side",
    "nextPageLabel": "Neste side",
    "previousPageLabel": "Forrige side",
    "rowsPerPageLabel": "Rader per side",
    "jumpToPageDropdownLabel": "Hopp til nestrekksmeny for side",
    "jumpToPageInputLabel": "Hopp til sideledetekst",
    "selectRow": "Rad valgt",
    "unselectRow": "Rad ikke valgt",
    "expandRow": "Rad utvidet",
    "collapseRow": "Rad kollapset",
    "showFilterMenu": "Vis filtermeny",
    "hideFilterMenu": "Skjul filtermeny",
    "filterOperator": "Filteroperator",
    "filterConstraint": "Filterbegrensning",
    "editRow": "Rediger rad",
    "saveEdit": "Lagre redigering",
    "cancelEdit": "Avbryt redigering",
    "listView": "Listevisning",
    "gridView": "Rutenettsvisning",
    "slide": "Bilde",
    "slideNumber": "{slideNumber}",
    "zoomImage": "Forstørr bilde",
    "zoomIn": "Forstørr",
    "zoomOut": "Forminsk",
    "rotateRight": "Roter til høyre",
    "rotateLeft": "Roter til venstre"
  }
}

