export default defineNuxtPlugin({
  enforce: 'pre', // clients will be ready to use by other plugins, Pinia stores etc.
  setup() {
    const { public: { openFetch: clients } } = useRuntimeConfig()

    return {
      provide: Object.fromEntries(Object.entries(clients).map(([name, options]) => [
        name,
        createOpenFetch(localOptions => ({
          ...options,
          ...localOptions,
          onRequest(ctx) {
            const auth = useAuthStore()
            
            if(auth.signedIn){
              if(typeof ctx.options.headers === 'undefined')
                ctx.options.headers = {}
              ctx.options.headers['Authorization'] = "Bearer "+ auth.token.jwttoken
            }

            return localOptions?.onRequest?.(ctx)
          }
        }))
      ]))
    }
  }
})