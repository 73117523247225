import { defineStore } from 'pinia'
import { jwtDecode } from "jwt-decode";
import type { UserDtoRes } from '@/types/api';
import * as storage from '~/stores/localStorage'
import { getMessaging, getToken  } from 'firebase/messaging'

const key = 'useAuthStore'

export const useAuthStore = defineStore(key, () => {

  const firebaseApp = useFirebaseApp()
  const messaging = getMessaging(firebaseApp)
  const config = useRuntimeConfig()
  
  
  const jwttoken = useCookie<string>('jwttoken', { default: () => null, sameSite: true, maxAge: 10368000})
  const refreshToken = useCookie<string>('refreshToken', { default: () => null, sameSite: true, maxAge: 10368000})
  
  const _data = ref<UserDtoRes>(null)
  const dateFetched = ref<Date>(null)
  
  const user = computed<UserDtoRes>(() => _data.value)
  const signedIn = computed(() => jwttoken.value)
  const token = computed<any>(() => {
    return{
      jwttoken: jwttoken.value,
      refreshToken: refreshToken.value,
    }
  })
  
  const init = () => {
    const data = load(key)
  
    if(data){
      _data.value = data.payload
      dateFetched.value = new Date(data.timestamp)
      get()
      return true
    }
      
    return get() 
  }

  
  const setToken = (token: Token) => {
    //const decoded = jwtDecode(token.jwttoken)
    jwttoken.value = token.jwttoken
    refreshToken.value = token.refreshToken
  }
  
  const removeToken = () => {
    jwttoken.value = null 
    refreshToken.value = null
  }
  
  const refresh = async() => {
    if(!refreshToken.value)
      return false 
    
    else{
      const {data, error} = await useApi('/auth/refresh', {
        method: 'POST', 
        body: {
          refreshToken: refreshToken.value, 
        }
      })
      
      if(error.value){
        removeToken()
        return false
      }
      
      setToken({
        jwttoken: data.value.jwttoken, 
        refreshToken: data.value.refreshToken
      })
    }
  }
  
  const logout = async() => {
    
    try{
      const currentToken = await getToken(messaging, { vapidKey: config.public.fcmKey})
      
      if (currentToken) {
        const { data, error } = await useApi('/device/delete/{device_token}', {
          method: 'DELETE',
          path: {
            device_token: currentToken
          }
        })
        
        if(error.value){
          console.log('/device/register error => ', error.value)
          throw error.value
        }
        console.log('remove device from notification service...')
      } 
      
      else
        console.log('No registration token available. Request permission to generate one.');
    }
    catch(err){
      console.log('failed to remove device from notification serivce...', err.value)
    }
    
    localStorage.clear()
    removeToken() 
  }
  
  
  
  const login = async(username: Ref<string>, password: Ref<string>) => {
    try{
      const {data, error} = await useApi('/auth/authenticate', {
        method: 'POST', 
        body: {
          username: username.value, 
          password: password.value
        }
      })
      
      if(error.value){
        throw error
      }
      
      setToken({
        jwttoken: data.value.jwttoken, 
        refreshToken: data.value.refreshToken
      })

      
    }
    catch(err){
      throw err
    }
  }
  
  const get = async() => {
    try{
      const {data, error} = await useApi('/user')
      
      if(error.value){
        throw error 
      }
      
      _data.value = data.value
      
      storage.set(key, _data.value)
      
    }
    catch(err){
      throw err
    }
  }

  return { login, refresh, signedIn, logout, token, user, get, init }
  
})