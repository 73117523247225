import { default as indexYuefL4mrxkMeta } from "/opt/build/repo/pages/auth/create/index.vue?macro=true";
import { default as loginMdibZuifKQMeta } from "/opt/build/repo/pages/auth/login.vue?macro=true";
import { default as _91token_93KZEgToIUpdMeta } from "/opt/build/repo/pages/auth/password/[token].vue?macro=true";
import { default as recoverKfGP52ph9hMeta } from "/opt/build/repo/pages/auth/recover.vue?macro=true";
import { default as indexncq6yOp5T9Meta } from "/opt/build/repo/pages/company/[companyId]/index.vue?macro=true";
import { default as add2VCbtkq4EVMeta } from "/opt/build/repo/pages/company/[companyId]/users/add.vue?macro=true";
import { default as create6dX4AAbsWxMeta } from "/opt/build/repo/pages/company/create.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as createrekyx0MEp9Meta } from "/opt/build/repo/pages/projects/[project]/agreement/create.vue?macro=true";
import { default as indexBFYdFq64qpMeta } from "/opt/build/repo/pages/projects/[project]/index.vue?macro=true";
import { default as indexXDhwfMOCtCMeta } from "/opt/build/repo/pages/projects/[project]/threads/[thread]/index.vue?macro=true";
import { default as messagesUjkTiWe2ESMeta } from "/opt/build/repo/pages/projects/[project]/threads/[thread]/messages.vue?macro=true";
import { default as settings4jztzTSaXXMeta } from "/opt/build/repo/pages/projects/[project]/threads/[thread]/settings.vue?macro=true";
import { default as createq6s5uNfy2kMeta } from "/opt/build/repo/pages/projects/[project]/threads/create.vue?macro=true";
import { default as indext7SwVHwTBtMeta } from "/opt/build/repo/pages/projects/[project]/threads/index.vue?macro=true";
import { default as selectoKTCc7NF9iMeta } from "/opt/build/repo/pages/projects/[project]/threads/select.vue?macro=true";
import { default as tablezN7CHNe7k0Meta } from "/opt/build/repo/pages/projects/[project]/threads/table.vue?macro=true";
import { default as typeb4hFQ0BQF6Meta } from "/opt/build/repo/pages/projects/[project]/threads/type.vue?macro=true";
import { default as employee79UZLTYiM6Meta } from "/opt/build/repo/pages/projects/[project]/users/add/employee.vue?macro=true";
import { default as otheriEDQUJ0HLOMeta } from "/opt/build/repo/pages/projects/[project]/users/add/other.vue?macro=true";
import { default as typegCf5a25bwKMeta } from "/opt/build/repo/pages/projects/[project]/users/add/type.vue?macro=true";
import { default as createibc2IBv4XfMeta } from "/opt/build/repo/pages/projects/create.vue?macro=true";
import { default as indexum7j5cysEXMeta } from "/opt/build/repo/pages/users/[id]/index.vue?macro=true";
import { default as settingsx8C3JnvIYtMeta } from "/opt/build/repo/pages/users/settings.vue?macro=true";
export default [
  {
    name: indexYuefL4mrxkMeta?.name ?? "auth-create",
    path: indexYuefL4mrxkMeta?.path ?? "/auth/create",
    meta: indexYuefL4mrxkMeta || {},
    alias: indexYuefL4mrxkMeta?.alias || [],
    redirect: indexYuefL4mrxkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/create/index.vue").then(m => m.default || m)
  },
  {
    name: loginMdibZuifKQMeta?.name ?? "auth-login",
    path: loginMdibZuifKQMeta?.path ?? "/auth/login",
    meta: loginMdibZuifKQMeta || {},
    alias: loginMdibZuifKQMeta?.alias || [],
    redirect: loginMdibZuifKQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93KZEgToIUpdMeta?.name ?? "auth-password-token",
    path: _91token_93KZEgToIUpdMeta?.path ?? "/auth/password/:token()",
    meta: _91token_93KZEgToIUpdMeta || {},
    alias: _91token_93KZEgToIUpdMeta?.alias || [],
    redirect: _91token_93KZEgToIUpdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/password/[token].vue").then(m => m.default || m)
  },
  {
    name: recoverKfGP52ph9hMeta?.name ?? "auth-recover",
    path: recoverKfGP52ph9hMeta?.path ?? "/auth/recover",
    meta: recoverKfGP52ph9hMeta || {},
    alias: recoverKfGP52ph9hMeta?.alias || [],
    redirect: recoverKfGP52ph9hMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: indexncq6yOp5T9Meta?.name ?? "company-companyId",
    path: indexncq6yOp5T9Meta?.path ?? "/company/:companyId()",
    meta: indexncq6yOp5T9Meta || {},
    alias: indexncq6yOp5T9Meta?.alias || [],
    redirect: indexncq6yOp5T9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/company/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: add2VCbtkq4EVMeta?.name ?? "company-companyId-users-add",
    path: add2VCbtkq4EVMeta?.path ?? "/company/:companyId()/users/add",
    meta: add2VCbtkq4EVMeta || {},
    alias: add2VCbtkq4EVMeta?.alias || [],
    redirect: add2VCbtkq4EVMeta?.redirect,
    component: () => import("/opt/build/repo/pages/company/[companyId]/users/add.vue").then(m => m.default || m)
  },
  {
    name: create6dX4AAbsWxMeta?.name ?? "company-create",
    path: create6dX4AAbsWxMeta?.path ?? "/company/create",
    meta: create6dX4AAbsWxMeta || {},
    alias: create6dX4AAbsWxMeta?.alias || [],
    redirect: create6dX4AAbsWxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: createrekyx0MEp9Meta?.name ?? "projects-project-agreement-create",
    path: createrekyx0MEp9Meta?.path ?? "/projects/:project()/agreement/create",
    meta: createrekyx0MEp9Meta || {},
    alias: createrekyx0MEp9Meta?.alias || [],
    redirect: createrekyx0MEp9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/agreement/create.vue").then(m => m.default || m)
  },
  {
    name: indexBFYdFq64qpMeta?.name ?? "projects-project",
    path: indexBFYdFq64qpMeta?.path ?? "/projects/:project()",
    meta: indexBFYdFq64qpMeta || {},
    alias: indexBFYdFq64qpMeta?.alias || [],
    redirect: indexBFYdFq64qpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXDhwfMOCtCMeta?.name ?? "projects-project-threads-thread",
    path: indexXDhwfMOCtCMeta?.path ?? "/projects/:project()/threads/:thread()",
    meta: indexXDhwfMOCtCMeta || {},
    alias: indexXDhwfMOCtCMeta?.alias || [],
    redirect: indexXDhwfMOCtCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/[thread]/index.vue").then(m => m.default || m)
  },
  {
    name: messagesUjkTiWe2ESMeta?.name ?? "projects-project-threads-thread-messages",
    path: messagesUjkTiWe2ESMeta?.path ?? "/projects/:project()/threads/:thread()/messages",
    meta: messagesUjkTiWe2ESMeta || {},
    alias: messagesUjkTiWe2ESMeta?.alias || [],
    redirect: messagesUjkTiWe2ESMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/[thread]/messages.vue").then(m => m.default || m)
  },
  {
    name: settings4jztzTSaXXMeta?.name ?? "projects-project-threads-thread-settings",
    path: settings4jztzTSaXXMeta?.path ?? "/projects/:project()/threads/:thread()/settings",
    meta: settings4jztzTSaXXMeta || {},
    alias: settings4jztzTSaXXMeta?.alias || [],
    redirect: settings4jztzTSaXXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/[thread]/settings.vue").then(m => m.default || m)
  },
  {
    name: createq6s5uNfy2kMeta?.name ?? "projects-project-threads-create",
    path: createq6s5uNfy2kMeta?.path ?? "/projects/:project()/threads/create",
    meta: createq6s5uNfy2kMeta || {},
    alias: createq6s5uNfy2kMeta?.alias || [],
    redirect: createq6s5uNfy2kMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/create.vue").then(m => m.default || m)
  },
  {
    name: indext7SwVHwTBtMeta?.name ?? "projects-project-threads",
    path: indext7SwVHwTBtMeta?.path ?? "/projects/:project()/threads",
    meta: indext7SwVHwTBtMeta || {},
    alias: indext7SwVHwTBtMeta?.alias || [],
    redirect: indext7SwVHwTBtMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/index.vue").then(m => m.default || m)
  },
  {
    name: selectoKTCc7NF9iMeta?.name ?? "projects-project-threads-select",
    path: selectoKTCc7NF9iMeta?.path ?? "/projects/:project()/threads/select",
    meta: selectoKTCc7NF9iMeta || {},
    alias: selectoKTCc7NF9iMeta?.alias || [],
    redirect: selectoKTCc7NF9iMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/select.vue").then(m => m.default || m)
  },
  {
    name: tablezN7CHNe7k0Meta?.name ?? "projects-project-threads-table",
    path: tablezN7CHNe7k0Meta?.path ?? "/projects/:project()/threads/table",
    meta: tablezN7CHNe7k0Meta || {},
    alias: tablezN7CHNe7k0Meta?.alias || [],
    redirect: tablezN7CHNe7k0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/table.vue").then(m => m.default || m)
  },
  {
    name: typeb4hFQ0BQF6Meta?.name ?? "projects-project-threads-type",
    path: typeb4hFQ0BQF6Meta?.path ?? "/projects/:project()/threads/type",
    meta: typeb4hFQ0BQF6Meta || {},
    alias: typeb4hFQ0BQF6Meta?.alias || [],
    redirect: typeb4hFQ0BQF6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/threads/type.vue").then(m => m.default || m)
  },
  {
    name: employee79UZLTYiM6Meta?.name ?? "projects-project-users-add-employee",
    path: employee79UZLTYiM6Meta?.path ?? "/projects/:project()/users/add/employee",
    meta: employee79UZLTYiM6Meta || {},
    alias: employee79UZLTYiM6Meta?.alias || [],
    redirect: employee79UZLTYiM6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/users/add/employee.vue").then(m => m.default || m)
  },
  {
    name: otheriEDQUJ0HLOMeta?.name ?? "projects-project-users-add-other",
    path: otheriEDQUJ0HLOMeta?.path ?? "/projects/:project()/users/add/other",
    meta: otheriEDQUJ0HLOMeta || {},
    alias: otheriEDQUJ0HLOMeta?.alias || [],
    redirect: otheriEDQUJ0HLOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/users/add/other.vue").then(m => m.default || m)
  },
  {
    name: typegCf5a25bwKMeta?.name ?? "projects-project-users-add-type",
    path: typegCf5a25bwKMeta?.path ?? "/projects/:project()/users/add/type",
    meta: typegCf5a25bwKMeta || {},
    alias: typegCf5a25bwKMeta?.alias || [],
    redirect: typegCf5a25bwKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/[project]/users/add/type.vue").then(m => m.default || m)
  },
  {
    name: createibc2IBv4XfMeta?.name ?? "projects-create",
    path: createibc2IBv4XfMeta?.path ?? "/projects/create",
    meta: createibc2IBv4XfMeta || {},
    alias: createibc2IBv4XfMeta?.alias || [],
    redirect: createibc2IBv4XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: indexum7j5cysEXMeta?.name ?? "users-id",
    path: indexum7j5cysEXMeta?.path ?? "/users/:id()",
    meta: indexum7j5cysEXMeta || {},
    alias: indexum7j5cysEXMeta?.alias || [],
    redirect: indexum7j5cysEXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: settingsx8C3JnvIYtMeta?.name ?? "users-settings",
    path: settingsx8C3JnvIYtMeta?.path ?? "/users/settings",
    meta: settingsx8C3JnvIYtMeta || {},
    alias: settingsx8C3JnvIYtMeta?.alias || [],
    redirect: settingsx8C3JnvIYtMeta?.redirect,
    component: () => import("/opt/build/repo/pages/users/settings.vue").then(m => m.default || m)
  }
]